.LeadStatus {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
}

.LeadDetailsBox {
  // align-items: flex-start;
  // justify-content: space-between;
  // display: flex;
  // flex-direction: row;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  gap: 26px;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    grid-template-columns: 1fr;
  }
}

.LeadDetailLeftBox {
  background-color: #fff;
  // max-width: 826px;
  // width: 100%;
  // height: 617px;
  // height: 100%;
  // padding: 16px;
  padding: 32px 32px 8px 32px;
  border-radius: 25px;

  @media only screen and (max-width: 1000px) {
    width: 100%;
    height: auto;
  }
}

.LeadDetailRightBox {
  // max-width: 460px;
  // width: 100%;
  // height: 665px;
  border-radius: 25px;
  padding: 6px 20px;
  background-color: #fff;
  @media only screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
}

.DetailsOrMarketing {
  display: flex;
  font-size: 16px;
  gap: 10px;
}

.LeadDetailEditBox {
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 30px;
  padding-bottom: 30px;
  &_title {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: #2659ed;
    margin-bottom: 20px;
    align-items: center;
    gap: 15px;
    p {
      width: 100%;
      margin: 0;
    }
    button {
      background-color: #2659ed;
      box-shadow: unset;
    }
  }
  &:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.LeadDetail {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  gap: 32px;

  @media only screen and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.LeadDetailInsideBox {
  h3 {
    font-size: 16px;
    line-height: 21px;
    color: #404b7c;
    font-weight: 700;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    color: #14181f;
    font-weight: 600;
    opacity: 0.5;
  }
}

.selectedTab {
  cursor: pointer;
  font-weight: 500;
  color: #2659ed;
  border-bottom: 1px solid #2659ed;
  padding: 9px 13px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
}

.notSelectedTab {
  cursor: pointer;
  color: #637ba5;
  font-weight: 500;
  padding: 9px 13px;
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
}

.DetailsInside {
  width: 800px;
  display: flex;
  gap: 20px;
  // padding-bottom: 24px;
  // border-bottom: 1px solid #d6deeb;
  flex-direction: row;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
}

hr {
  border: 1px solid #dcdcdc;
}

.DetailsEachBox {
  width: 390px;

  padding: 10px 0px 0px 24px;

  h1 {
    font-size: 18px;
    color: #637ba5;
    font-weight: 700;
  }
  p {
    font-size: 18px;
    font-weight: 700;
  }
}
.DetailsEachTitle {
  display: flex;
  gap: 10px;
  align-items: center;
  h2 {
    font-size: 18px;
    font-weight: 700;
    color: #404b7c;
  }
}

.ActivityInside {
  // margin-top: 10px;
  // display: flex;
  // flex-direction: row;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  

  @media only screen and (max-width: 767px) {
    gap: 5px;
    flex-direction: column;
    align-items: center;
  }
}
// .ActivityInsideBox {
//   margin-top: 10px;
//   display: flex;
//   gap: 10px;
//   @media only screen and (max-width: 767px) {
//     gap: 5px;
//   }
// }

.ChipBtn {
  border: 1px solid #637ba5 !important;
  // padding: 17px 4px !important;
  height: auto !important;
  border-radius: 30px !important;
  padding: 15px 20px 15px 20px !important;
  gap: 10px;

  span {
    color: #637ba5;
    padding: 0;
    font-size: 16px;
    line-height: 21px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
  }
}
.selectedChip {
  border-color: #2659ed !important;
  height: auto !important;
  border-radius: 30px !important;
  padding: 15px 20px 15px 20px !important;
  gap: 10px;
  
  span {
    color: #2659ed;
    padding: 0;
    font-size: 16px;
    line-height: 21px;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
  }
}

.css-spf0x2-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 30px !important;
}

.InputBox {
  display: grid;
  grid-template-columns: auto 109px;
  gap: 20px;
  .MuiInputBase-root {
    height: auto !important;
    input {
      padding: 10px !important;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      &::placeholder {
        color: #404b7c80;
      }
    }
    fieldset {
      border-color: #d9e4ff;
    }
  }
  button {
    background-color: #2659ed;
    box-shadow: unset;
    padding: 0;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
}

$base: 38px;

.breadContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 480px;
  // padding: 0 40px;
  .breadcrumb {
    display: flex;
    border-radius: 6px;
    overflow: hidden;
    margin: auto;
    text-align: center;
    width: 100%;
    height: 50px;
    // background-color: #f3f6fd;
    font-size: 14px;
    // padding: 0.75rem 0rem !important;
  }
  .breadcrumb a {
    position: relative;
    display: flex;
    flex-grow: 1;
    text-decoration: none;
    margin: auto;
    height: 100%;
    padding-right: 0;
    color: #666;
    background-color: #637ba533;
    padding-left: 38px;
    background-color: #637ba533;
    cursor: pointer;
  }

  .breadcrumb a:first-child {
    padding-left: $base / 2.5;
    border-radius: 20px 0 0 20px;
    // background: #637ba5;
    // color: #fff;
  }

  .breadcrumb a:last-child {
    padding-right: $base / 2.5;
    border-radius: 0px 20px 20px 0px;
  }

  .breadcrumb a:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: 50px;
    height: 50px;
    top: 0;
    right: $base / 1.35 * -0.8;
    background-color: rgb(214 222 235);
    border-top-right-radius: 5px;
    transform: scale(0.707) rotate(45deg);
    box-shadow: 5px -5px #ffffff;
    z-index: 1;
  }

  .breadcrumb a:last-child:after {
    content: none;
  }

  .breadcrumb__inner {
    display: flex;
    flex-direction: column;
    margin: auto;
    z-index: 2;
  }

  .blueBackground {
    color: #fff;
    background-color: #637ba5;
  }

  .selectedBackground {
    color: #fff;
    background-color: #637ba5 !important;
  }
  .selectedBackground:after {
    background-color: #637ba5 !important;
  }

  .breadcrumb__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  // .breadcrumb a.active, .breadcrumb a:hover{
  // 	background: #637BA5;
  // 	color: white;
  // }

  // .breadcrumb a.active:after, .breadcrumb a:hover:after {
  // 	background: #637BA5;
  // 	color: white;
  // }

  @media only screen and (max-width: 760px) {
    display: none;
  }
}
.greenBackground {
  color: #fff;
  background-color: green !important;
}
.breadContainerMobile {
  display: none;

  @media only screen and (max-width: 760px) {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    // min-width: 480px;
    .breadcrumb {
      display: flex;
      border-radius: 6px;
      overflow: hidden;
      margin: auto;
      text-align: center;
      width: 100%;
      // height: 80px;
      background-color: #666;
      font-size: 14px;
      padding: 0.75rem 0rem !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      &__innerBox {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
      }
    }
    .breadcrumb__box {
      background: #f3f6fd;
      padding: 5px 20px;
      border-radius: 20px;
    }
    .breadcrumb__inner {
      display: flex;
      flex-direction: column;
      margin: auto;
      // z-index: 2;
    }

    .breadcrumb__title {
      font-weight: bold;
    }
  }
}

// @media all and (max-width: 1000px) {
//   .breadcrumb {
//     font-size: 12px;
//   }
// }
// @media all and (max-width: 710px) {
//   .breadcrumb__desc {
//     display: none;
//   }

//   .breadcrumb {
//     height: 62px;
//   }

//   .breadcrumb a {
//     padding-left: $base / 1.5;
//   }

//   .breadcrumb a:after {
//     content: '';
//     width: $base * 1;
//     height: $base * 1;
//     right: $base / 2 * -1;
//     transform: scale(0.707) rotate(45deg);
//   }
// }

.LeadStatusBtn {
  background-color: #2659ed;
  border-radius: 30px;
  color: #fff;
  padding: 12px;
  width: 100%;
  border: none;
  max-width: 253px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
}

.ActivityBox {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
