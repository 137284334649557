// styles.scss

$font-family: "Arial, sans-serif";
$primary-color: #333;
$secondary-color: #666;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  font-family: $font-family;
}

.title {
  font-size: 2em;
  font-weight: bold;
  margin: 10px 0;
  color: $primary-color;
}

.message {
  font-size: 1.2em;
  color: $secondary-color;
}
