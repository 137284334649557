.mainHeader {
  position: sticky;
  top: 0 !important;
  // background: #fff;
  z-index: 10;

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background: #f6f8fd;

    &-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 60px;

      h6 {
        font-weight: 700;
        color: #2659ed;
        font-family: "Nunito Sans", sans-serif;
        font-size: 25px;
      }
      button {
        background-color: #2659ed;
        box-shadow: unset;
      }
    }

    p {
      font-size: 20px;
      line-height: 24px;
      // color: #000000;
      font-family: "Nunito Sans", sans-serif;
      font-weight: 600;
      color: #14181f;
    }

    svg {
      height: 22px;
      width: 22px;
    }
  }

  &__field {
    .langBox select {
      border: 1px solid #01393f !important;
      padding: 6px !important;
    }
  }

  .menuIcon {
    padding: 10px;
    display: none;
    @media only screen and (max-width: 767px) {
      display: block;
    }
  }
}


.searchBox {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 215px;

  .MuiFormControl-root {
    width: 100%;
  }

  & > svg {
    position: absolute;
    left: 10px;
  }

  input {
    padding: 8px 10px;
    height: 30px;
    box-sizing: border-box;
    padding-left: 35px;
    font-size: 12px;
    line-height: 12px;
    color: #000;
  }

  fieldset {
    border: 1px solid #01393f;
    border-radius: 0;
    height: 34px;
  }
}

.notificationBtn {
  display: flex;
  align-items: center;
  height: 30px;
  width: 30px;
  justify-content: center;
  position: relative;
  cursor: pointer;

  svg {
    height: 22px;
    width: 22px;
  }

  &:after {
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: absolute;
    right: 6px;
    top: 4px;
    background-color: transparent;
  }

  &--active {
    &::after {
      background-color: #ff8383;
    }
  }
}
