.leadstable {
  height: calc(100vh - 91px);
  table {
    th {
      color: #2659ed !important;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
    }

    td {
      color: #14181f;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
    }
  }
}
body {
  background-color: #f3f6fdbf;
}
.leadstableHeader {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  .CustomsearchBox {
    .MuiInputBase-root {
      background-color: transparent !important;
    }
    fieldset {
      //   border: 1px solid #d9e4ff;
    }
    input {
      padding: 0;
      font-weight: 600;
      font-family: "Nunito Sans", sans-serif;
    }
  }
  .CustomSelect {
    color: #2659ed;
    fieldset {
      border: 1px solid #2659ed;
    }
    svg {
      color: #2659ed;
    }
  }
}

.Tablepagination {
  align-items: center;
  margin-top: 2rem;
}

.customPagination {
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.customPagination span,
.customPagination input,
.customPagination button P {
  color: #3c434b;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.customPagination__buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filter {
  color: #2659ed !important;
  border: 1px solid #2659ed !important;
  background-color: #fff !important;
  border-radius: 40px !important;
  width: 15% !important;
  height: 40px !important;

  &:focus {
    outline: none;
    border-color: 1px solid #2659ed !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }
}

.filter .MuiSelect-icon {
  color: #2659ed;
}

.deleteModel {
  .MuiBox-root {
    text-align: left;
  }
  &__inner {
    padding: 45px;
    @media only screen and (max-width: 600px) {
      padding: 35px;
    }
  }
  h6 {
    font-size: 24px;
    margin: 0 0 12px;
  }
  p {
    font-size: 18px;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    margin-top: 32px;
    button:last-child {
      background: red;
    }
  }
}
.LeadTable {
  border-radius: 24px !important;
  overflow: auto;
  position: relative;
  background-color: #fff !important;
  // height: calc(100vh - 191px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .MuiPaper-root {
    box-shadow: unset !important;
    position: relative;
    height: calc(100vh - 256px);
    overflow: auto;
  }
  table {
    th {
      text-align: left;
      padding: 16px 24px;
      border-bottom: 1px solid #f3f6fd;
    }
    td {
      text-align: left;
      border-bottom: 1px solid #f3f6fd;
      padding: 26px !important;
      cursor: pointer;
    }
  }
  .noData {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Tablepagination {
    padding: 20px 0;
    margin: 0;
    // position: absolute;
    // bottom: 0;
    width: 100%;
    background-color: #fff;
    ul {
      li {
        button {
          width: 23px;
          height: 23px;
          padding: 0;
          margin: 0;
          color: #656c7e;
          font-size: 14px;
          line-height: 19px;
          border-radius: 4px;
          background-color: transparent;
          &.Mui-selected {
            box-shadow: 0 1px 3px #0000001a;
          }
        }
      }
    }
  }
}
.LeadDialog {
  &__Title {
    padding: 48px 48px 40px !important;
    font-size: 22px !important;
    line-height: 30px !important;
    color: #404b7c !important;
    font-weight: 600 !important;
    justify-content: space-between;
    display: flex;
    text-transform: capitalize;
    button {
      padding: 0;
    }
  }
  .titleInner {
    font-size: 18px;
    color: #404b7c;
    line-height: 24px;
    font-weight: 700;
  }
  &__innerTitle {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #404b7c;
    margin-bottom: 24px;
  }
  label {
    font-size: 16px;
    line-height: 21px;
    color: #404b7c;
    margin-bottom: 15px;
    font-weight: 700;
    display: block;
    width: 100%;
  }
  &__BoxDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 42px;
  }
  .FormGroup {
    margin-bottom: 24px;
    .FormLabel {
      margin-bottom: 9px;
      font-weight: 600;
    }
    .FormControl {
      color: #404b7c;
      font-size: 16px;
      line-height: 21px;
      font-weight: 600;
      width: 100%;
      &::placeholder {
        color: #404b7c80;
      }
      .MuiInputBase-root {
        height: auto !important;
      }
      input {
        padding: 10px 20px;
        font-weight: 600;
        color: #404b7c;
        &::placeholder {
          color: #404b7c80;
        }
      }
      fieldset {
        border-radius: 31px;
        border: 1px solid #d9e4ff;
      }
    }
    .FormSelect {
      color: #404b7c;
      font-size: 16px;
      line-height: 21px;
      font-weight: 600;
      width: 100%;
      &::placeholder {
        color: #404b7c80;
      }
      fieldset {
        border-radius: 31px;
        border: 1px solid #d9e4ff;
      }
    }
    textarea {
      border-radius: 16px;
      border: 1px solid #d9e4ff !important;
      background: transparent;
      padding: 16px 23px;
      width: calc(100% - 49px) !important;
      font-size: 15px !important;
      &::placeholder {
        color: #404b7c;
      }
    }
  }
  &__DialogButton {
    border-top: 1px solid #404b7c33;
    padding: 30px 40px 40px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    // button {
    //   border: 1px solid #2659ed;
    //   box-shadow: unset;
    //   color: #fff;
    //   font-size: 18px;
    //   line-height: 24px;
    //   font-weight: 600;
    //   padding: 11px 40px;
    //   &.SaveBtn {
    //     background-color: #2659ed;
    //     color: #fff;
    //   }
    // }
  }
  .FormCheckBox {
    .MuiCheckbox-root {
      margin: 0;
      padding: 0;
    }
    &.MuiFormControlLabel-root {
      margin: 0;
    }
    input,
    svg {
      // opacity: 0 !important;
    }
    .MuiTouchRipple-root {
      border: 1px solid #d9e4ff;
      border-radius: 6px;
    }
  }
}
.FormInnerBox {
  height: calc(100vh - 343px);
  overflow: auto;
  padding: 0 48px 40px;
}
.fileCustomField {
  position: relative;
  width: fit-content;
  cursor: pointer;
  .MuiFormControl-root {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
  }
  input {
    padding: 0;
    cursor: pointer;
  }
  .fileCustomBtn {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #2659ed;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
  }
}
.errorMsg {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #d32f2f;
}
