.userBlock {
  &__image {
    svg {
      height: 22px;
      width: 22px;
      color: #ffffff;
    }
  }

  &__name {
    font-weight: 900 !important;
    font-size: 0 !important;
    line-height: 18px !important;
    color: #ffffff;
    margin-bottom: 2px !important;
    transition: all 0.5s ease;
    white-space: nowrap;
    opacity: 0%;
  }

  &__profile {
    font-weight: 500 !important;
    font-size: 0 !important;
    line-height: 15px !important;
    color: #42db89;
    transition: all 0.5s ease;
    white-space: nowrap;
    opacity: 0%;
  }
}

.sidebar {
  height: 100vh;
  // top: 52px;
  top: 0;
  position: fixed;
  border-radius: 0px 25px 25px 0px;
  background: #2659ed;
  transition: all 0.3s ease;

  &__logo {
    padding: 8px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    gap: 15px;
    color: #fff;
    font-weight: 600;
    span {
      font-size: 0;
      transition: all 0.2s ease;
    }
  }

  img {
    height: auto;
    width: 28px;
    height: 28px;
    transform: scale(0.9);
  }
  // svg {
  //   height: 30px;
  //   width: 40px;
  // }

  &__menu {
    .menuItem {
      padding: 15px 0;
      font-weight: 900;

      line-height: 12px;
      color: #ffffff;

      &__link {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 8px;
        border-radius: 8px;
        transition: all 0.2s ease;
        cursor: pointer;

        span {
          font-size: 0;
          transition: all 0.5s ease;
          white-space: nowrap;
          opacity: 0%;
          font-family: "Nunito Sans", sans-serif;
        }

        svg {
          height: 22px;
          width: 22px;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 22px;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.29);
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &--active {
    width: 62px;

    .userBlock__name {
      font-size: 14px !important;
      opacity: 100%;
    }

    .userBlock__profile {
      font-size: 15px !important;
      opacity: 100%;
    }

    .menuItem {
      &__link {
        span {
          //   font-size: 16px;
          font-size: 0;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          opacity: 100%;
          font-family: "Nunito Sans", sans-serif;
        }
      }
    }
  }
}

.pageWrapper {
  width: calc(100vw - 148px);
  transition: all 0.5s ease;
  padding: 0 34px;
  margin-left: auto;
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 35px 11px;
}

.sidebar.sidebar--active {
  &:hover {
    width: 240px;
    .menuItem__link {
      span {
        font-size: 16px;
      }
    }
    .sidebar__logo {
      span {
        font-size: 21px;
      }
    }
  }
}
.sidebar.sidebar--active:hover ~ .pageWrapper {
  width: calc(100vw - 325px);
}
.sidebarSmall.sidebarSmall--active {
      width: 100%;
      z-index: 999;
      border-radius: 0;
    .menuItem__link {
      span {
        font-size: 16px;
      }
    }
    .sidebar__logo {
      span {
        font-size: 21px;
      }
    }
  }

.mobileMenuBtn {
  display: none !important;
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.tableDetailsSlide {
  .MuiTabs-root {
    button {
      min-width: 0 !important;

      &.Mui-selected {
        background-color: #f1f1f1;
        color: #01393f;
      }
    }
  }

  .MuiTabs-indicator {
    background-color: #01393f !important;
  }

  .MuiTable-root {
    td {
      font-size: 14px;
      border: none !important;
      padding: 15px 8px;
    }

    th {
      font-size: 14px;
      color: #01393f;
      border: none !important;
      padding: 12px 8px;
    }
  }

  div[role="tabpanel"] > div {
    padding: 25px 0 !important;
  }

  .closeBtn {
    color: #01393f;
  }
}

@media only screen and (max-width: 899px) {
  .tableDetailsSlide {
    & > div {
      border: none !important;
      padding: 0 !important;
    }
  }
}

@media only screen and (max-width: 991px) {
  .mobileMenuBtn {
    display: flex !important;
  }

  .sidebar {
    width: 250px;
    transform: translateX(0px);

    .userBlock__name {
      font-size: 14px !important;
      opacity: 100%;
    }

    .userBlock__profile {
      font-size: 15px !important;
      opacity: 100%;
    }

    .menuItem {
      &__link {
        span {
          font-size: 12px;

          opacity: 100%;
        }
      }
    }

    &--active {
      transform: translateX(-250px);
    }
  }

  .pageWrapper {
    width: 100vw;
    transition: all 0.5s ease;
  }

  .sidebar.sidebar--active + div {
    width: 100vw;
  }
}
