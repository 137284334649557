.containerForget {
  min-height: 100vh;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #fff;

  &__inner {
    background-color: #f3f6fd;
    padding: 55px 55px;
    max-width: 614px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    box-sizing: border-box;

    label {
      opacity: 0.5;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      opacity: 0.5;
      transform: translate(0px, 4px) scale(0.75);
    }
    fieldset {
      border: none;
    }
    input {
      padding: 25px 12px 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
      &:focus {
        border-bottom: 2px solid #1976d2 !important;
      }
    }
    .MuiInputBase-root {
      background-color: transparent !important;
      border-radius: 0 !important;
    }
  }

  &__btn {
    display: flex;
    justify-content: flex-end;

    .formBtn {
      padding: 15px 50px;
      border-radius: 31px;
      font-weight: 600;
      margin-top: 15px !important;
      height: 55px;
    }
  }
}
