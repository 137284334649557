.ProfileDrp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 16px 16px;
  h6 {
    color: #2659ed;
    font-size: 18px;
    margin-top: 10px;
    line-height: 24px;
  }
  p {
    line-height: normal;
    color: #14181f;
  }
}
.ProfileTab {
  margin: 0;
  padding: 0;
  li {
    border-top: 1px solid #dcdcdc !important;
    padding: 12px 25px;
    &.Mui-selected,
    &:hover {
      background-color: #2659ed14;
    }
  }
}
