.containerSignin {
  min-height: 100vh;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #fff;

  &__details {
    background-color: #f3f6fd;
    padding: 55px 55px;
    max-width: 614px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .MuiFormLabel-filled {
      // transform: translate(14px, -9px) scale(0.75);
    }
    label {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      opacity: 0.5;
      transform: translate(0px, 4px) scale(0.75);
    }
    .MuiInputBase-root {
      background-color: transparent !important;
      border-radius: 0 !important;
      &.Mui-error{
        &::before {
          border-bottom: 2px solid rgb(255, 95, 95);
        }
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
      width: 100%;
      box-sizing: border-box;

      .forget {
        font-size: 1rem;
        color: #2659ed;
        text-decoration: none;
      }

      .btn {
        display: flex;
        justify-content: flex-end;
      }
      &__formBtn {
        padding: 15px 50px;
        border-radius: 31px;
        font-weight: 600;
        margin-top: 15px !important;
        height: 55px;
        box-shadow: none;
      }
    }
  }

  &__errorContainer {
    width: 100%;
    .error {
      border: 2px solid #bb1305;
      background: #bb13051a;
      width: 92%;
      padding: 10px 20px;

      .errorDetails {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // flex-wrap: wrap;
        .errorText {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          p {
            font-size: 1rem;
            font-weight: 400;
            text-align: center;
          }
        }
      }
    }
  }
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  //   padding: 10px !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 100px !important;
  height: 50px !important;
}
